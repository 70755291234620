<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Queued Job Logs" page-icon="mdi-comment-processing">
    <g-list-page
        :headers="headers"
        :actions="actions"
        stateendpoint="queuedJobLogs.queuedLogs"
        sort="queue"
        @loadData="loadData"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(queue name)"
                @applySearch="handleSearch"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
  </g-card>

</template>
<script>
import ListMixins from "../../components/mixins/list/List";
import GCard from "../../components/GCard";
import GListPage from "../../components/list/GListPage";
import PageOverlay from "../../components/PageOverlay";
import GPSearch from "../../components/GPSearch";

export default {
  name: 'QueuedJobLogs',
  metaInfo: {
    title: 'shantoitfirm.com',
    titleTemplate: 'Admin Dashboard - Queued Job Logs | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    GPSearch,
    PageOverlay,
    GListPage,
    GCard,
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: 'Queue Name',
          value: 'queue'
        },
        {
          text: 'Total Queued',
          align: 'right',
          value: 'totalQueued'
        }
      ],
      actions: {
        load: 'queuedJobLogs/load'
      }
    }
  }
}
</script>
